<template>
  <div class="violations">
    <h1>Violations</h1>
    <b-container>
      <b-form class="mt-5" @submit="onSubmit" @reset="onReset">
        <b-form-group
          id="fs-pws-id"
          label="PWS ID (7 digits)"
          label-for="pws-id"
        >
          <b-form-input
            id="pws-id"
            v-model="pws_id"
            type="number"
            style="text-align:center;"
          ></b-form-input>
        </b-form-group>
        <p>{{ this.pws_name }}</p>

        <b-form-row>
          <b-col>
            <b-form-group
              id="fs-determination-date-1"
              label="Determination Date Start"
              label-for="determination-date-1"
            >
              <b-form-input
                type="date"
                id="determination-date-1"
                v-model="determination_date_1"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="fs-determination-date-2"
              label="Determination Date End"
              label-for="determination-date-2"
            >
              <b-form-input
                type="date"
                id="determination-date-2"
                v-model="determination_date_2"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="fs-state-begin-dt"
              label="State Period Begin Date"
              label-for="state-begin-dt"
            >
              <b-form-input
                type="date"
                id="state-begin-dt"
                v-model="state_begin_dt"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="fs-state-end-dt"
              label="State Period End Date"
              label-for="state-end-dt"
            >
              <b-form-input
                type="date"
                id="state-end-dt"
                v-model="state_end_dt"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-group
          id="fs-violation-types"
          label="Violation Types"
          label-for="violation-types"
        >
          <b-form-select
            id="violation-types"
            v-model="violation_types"
            :options="violation_types_options"
            multiple
            :select-size="5"
          ></b-form-select>
        </b-form-group>

        <b-row>
          <b-col>

            <b-form-group
              label="Violation Status Type Codes"
              description="Select violation status codes to include."
            >
              <b-form-checkbox-group
                v-model="violation_status_codes"
              >
                <b-form-checkbox value="D">Delete</b-form-checkbox>
                <b-form-checkbox value="P">Proposed</b-form-checkbox>
                <b-form-checkbox value="R">Reject</b-form-checkbox>
                <b-form-checkbox value="V">Validate</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

          </b-col>
          <b-col>

            <b-form-group
              label="Missing Public Notice (SIF)"
              description="Only include records missing Public Notice (SIF)."
            >
              <b-form-checkbox
                v-model="missing_public_notice"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button class="m-2" type="submit" variant="primary">Generate Data Table</b-button>
        <b-button class="m-2" @click="generateCSV" variant="primary">Generate CSV File</b-button>
        <b-button class="m-2" type="reset" variant="danger">Reset</b-button>
      </b-form>
      <div v-if="violation_data.length > 0">
        <h3 class="mt-3">Violation Data</h3>
        <b-pagination
          v-model="current_page"
          :per-page="per_page"
          :total-rows="violation_rows"
        ></b-pagination>
        <p>{{ violation_rows }} Violations (Max 1000 Records)</p>
        <b-table
          :current-page="current_page"
          :per-page="per_page"
          striped
          hover
          responsive
          :items="violation_data"
          :fields="violation_fields"
          :filter="filter"
          :filter-included-fields="filterOn"
        >
        <template #cell(state_prd_begin_dt)="data">
          {{ moment(data.value).format('YYYY-DD-MM') }}
        </template>
        <template #cell(state_prd_end_dt)="data">
          {{ moment(data.value).format('YYYY-DD-MM') }}
        </template>
        <template #cell(determination_date)="data">
          {{ moment(data.value).format('YYYY-DD-MM') }}
        </template>
        </b-table>
        <b-pagination
          v-model="current_page"
          :per-page="per_page"
          :total-rows="violation_rows"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      violation_fields: [
        { key: "pws_id", label: "PWS ID", sortable: true },
        { key: "pws_name", label: "PWS Name", sortable: false },
        { key: "population_count", label: "Population", sortable: true },
        { key: "type_code", label: "Vio Type Code", sortable: true },
        { key: "vt_name", label: "Vio Name", sortable: true },
        { key: "public_notice", label: "Public Notice", sortable: false },
        { key: "sif_status_date", label: "SIF Status Date", sortable: true },
        { key: "sox_status_date", label: "SOX Status Date", sortable: true },
        { key: "status_type_code", label: "Status", sortable: true },
        { key: "analyte_code", label: "Analyte", sortable: true },
        { key: "state_prd_begin_dt", label: "State Begin Date", sortable: true },
        { key: "state_prd_end_dt", label: "State End Date", sortable: true },
        {
          key: "determination_date",
          label: "Determination_Date",
          sortable: true
        }
      ],
      violation_status_codes: ['D','P','R','V'],
      missing_public_notice: false,
      filter: null,
      filterOn: [],
      violation_data: [],
      current_page: 1,
      per_page: 10,
      violation_types: [],
      violation_types_map: {},
      violation_types_options: [],
      pws_id: "",
      pws_id_v: "",
      pws_name: "No PWS Selected",
      determination_date_1: null,
      determination_date_2: null,
      state_begin_dt: null,
      state_end_dt: null
    }
  },
  mounted() {
    this.fetchViolationTypes()
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.fetchViolations()
    },
    generateCSV() {
      this.fetchViolations('csv')
    },
    onReset(event) {
      event.preventDefault()
      this.pws_id = ""
      this.pws_id_v = ""
      this.pws_name = "No PWS Selected"
      this.determination_date_1 = null
      this.determination_date_2 = null
      this.violation_types = []
      this.violation_data = []
      this.violation_status_codes = ['D','P','R','V']
      this.missing_public_notice = false
      this.state_begin_dt = null
      this.state_end_dt = null
      this.current_page = 1
      console.log("Reset")
    },
    async fetchPWSName() {
      const path = process.env.VUE_APP_API_URI + "/pws-name"
      try {
        console.log("checking " + this.pws_id)
        let response = await this.axios.get(path, {
          params: { pws_id: this.pws_id }
        })
        if (response.data) {
          this.pws_id_v = this.pws_id
          this.pws_name = response.data.pws_name
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchViolations(filetype = '') {
      const path = process.env.VUE_APP_API_URI + "/violations"
      try {
        console.log("checking " + this.pws_id)
        let response = await this.axios.post(path, {
          pws_id: this.pws_id,
          ddate1: this.determination_date_1,
          ddate2: this.determination_date_2,
          vtypes: this.violation_types,
          state_prd_begin_dt: this.state_begin_dt,
          state_prd_end_dt: this.state_end_dt,
          missing_public_notice: this.missing_public_notice,
          vio_status_codes: this.violation_status_codes,
          filetype: filetype,
        })
        //console.log(response.data)
        if (filetype == 'csv') {
          const csvdata = response.data.csv_data
          const anchor = document.createElement('a')
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvdata)
          anchor.target = '_blank'
          anchor.download = 'Violations.csv'
          anchor.click()
        }
        else {
          if (response.data.length > 0) {
            this.violation_data = response.data
          } else {
            this.violation_data = []
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchViolationTypes() {
      const path = process.env.VUE_APP_API_URI + "/violation-types"
      try {
        let response = await this.axios.get(path)
        if (response.data) {
          //this.violation_types_options = response.data
          let i = 0
          let vio_type_code = ""
          let vio_name = ""
          let is_no = ""
          let st_code = ""
          let vkey = ""
          let vvalue = ""
          for (i = 0; i < response.data.length; i++) {
            //console.log(response.data[i])
            is_no = response.data[i].tmnvtype_is_number
            st_code = response.data[i].tmnvtype_st_code
            vio_type_code = response.data[i].type_code
            vio_name = response.data[i].name
            vkey = is_no + "-" + st_code
            vvalue = `(${vio_type_code}) ` + vio_name
            this.violation_types_options.push({
              value: vkey,
              text: vvalue
            })
            this.violation_types_map[vkey] = vvalue
          }
          //console.log(this.violation_types_options)
          //console.log(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  watch: {
    pws_id: async function(newVal) {
      if (newVal.length === 7) {
        await this.fetchPWSName()
      } else if (newVal.length === 0) {
        this.pws_id_v = ""
        this.pws_name = "No PWS Selected"
      }
    }
  },
  computed: {
    violation_rows() {
      return this.violation_data.length
    }
  }
}
</script>
